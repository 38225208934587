const INIT_STATE = {
    title: "Tracking",
}

export const trackReducer = (state = INIT_STATE, action) => {
    switch(action.type){
        default:
            return { ...state }
    }
}

