exports.env = {
  api: {
    endpoint: {
      protocol_endpoint: window._env_.API_ENDPOINT_PROTOCOL || 'http',
      host_endpoint: window._env_.API_ENDPOINT_HOST || 'localhost',
      port_endpoint: window._env_.API_ENDPOINT_PORT || 5000,
      route_endpoint: window._env_.API_ENDPOINT_ROUTE || ''
    },
    tracking: {
      protocol_tracking: window._env_.API_TRACKING_PROTOCOL || 'http',
      host_tracking: window._env_.API_TRACKING_HOST || 'localhost',
      port_tracking: window._env_.API_TRACKING_PORT || 5000,
      route_tracking: window._env_.API_TRACKING_ROUTE || ''
    }
  },
  authentication: {
    enabled: window._env_.AUTH_ENABLED || true,
    realm: window._env_.AUTH_REALM || 'Sorgenia',
    authServer: {
      protocol: window._env_.AUTH_SERVER_PROTOCOL || 'http',
      host: window._env_.AUTH_SERVER_HOST || 'localhost',
      port: window._env_.AUTH_SERVER_PORT || 8083,
      route: window._env_.AUTH_SERVER_ROUTE || 'auth'
    },
    client: window._env_.AUTH_CLIENT || 'sorgenia-client'
  }
};
