import axios from "axios"
import { env } from '../../env'

const { protocol_endpoint, host_endpoint, port_endpoint, route_endpoint } = env.api.endpoint;
const { protocol_tracking, host_tracking, port_tracking, route_tracking } = env.api.tracking;

export const dataService = {   
    getRating: async (code, type, userInfo, keycloak) => {
        //console.log("Get Rating request, code, keycloack", code, type, userInfo, keycloak)
        const headers = {
            Accept: 'application/json'
        }

        if (keycloak) {
            await keycloak.updateToken(10)
            headers['Authorization'] = `Bearer ${keycloak.token}`
        }
        
        const search = axios.post(
            `${protocol_endpoint}://${host_endpoint}:${port_endpoint}${route_endpoint}/api/v1/info/rating_${type}/${code}`,
            {
                data: userInfo
            }, {
                headers,
                method: 'POST'
            }
        )
        return search;
    },
    sendTrack: async (code, type, userInfo, rating, error, keycloak) => {
        //console.log("Get Rating request, code, keycloack", code, type, userInfo, keycloak)
        const headers = {
            Accept: 'application/json'
        }

        if (keycloak) {
            await keycloak.updateToken(10)
            headers['Authorization'] = `Bearer ${keycloak.token}`
        }
        
        const track = axios.post(
            `${protocol_tracking}://${host_tracking}:${port_tracking}${route_tracking}/api/v1/tracking`,
            {
                data: userInfo,
                type: type,
                code: code,
                rating: rating,
                error: error
            }, {
                headers,
                method: 'POST'
            }
        )
        console.log(track)
        return track;
    }
}
