import React, { Suspense, useState, useEffect } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { RouteWithLayout } from '@moxoff/axiom-core';
import {
  Main as MainLayout
} from '../shared/layouts';

import { useKeycloak } from '@moxoff/axiom-auth';
import {limitedGroups, limitedGroupsAdmin} from '../util/constants/constants'

import LoadingRoute from '../components/LoadingRoute';
// import { camelToSnake } from '../util/helpers';
import Home from '../container/Home'
import Track from '../container/Track'
import Users from '../container/Users'

const Routes = () => {
  const { keycloak, authenticated } = useKeycloak();
  const [userGroup, setUserGroup] = useState(null)

  useEffect(() => {
    const loadUser = async () => {
      await keycloak()
        .loadUserInfo()
        .then(info => {
          setUserGroup(info.groups[0])
        });
    };
    if (userGroup === null && authenticated() === true) {
      loadUser();
    }
  }, [authenticated, keycloak, userGroup]);

  return (
    <Suspense
      fallback={
        <MainLayout>
          <LoadingRoute />
        </MainLayout>
      }
    >
      <Switch>
        <Redirect exact from="/" to="/output" />
        <RouteWithLayout
          component={Home}
          exact
          layout={MainLayout}
          path="/output"
        />
        {limitedGroups.includes(userGroup) ?
          <RouteWithLayout
            component={Track}
            exact
            layout={MainLayout}
            path="/track"
          /> : <Redirect to="/page-not-found" />}
        {limitedGroupsAdmin.includes(userGroup) ?
          <RouteWithLayout
            component={Users}
            exact
            layout={MainLayout}
            path="/users"
          /> : <Redirect to="/page-not-found" />}
        
        <Redirect to="/page-not-found" />
      </Switch>
    </Suspense>
  );
};

export default Routes;
