import { RATING_FAILED, RATING_REQUEST, RATING_SUCCESS, TRACK_REQUEST } from "./types";

export const ratingRequest = (code, codeType, userInfo, keycloak) => ({
    type: RATING_REQUEST,
    code,
    codeType,
    userInfo,
    keycloak
})

export const sendTrackRequest = (code, codeType, userInfo, rating, error, keycloak) => ({
    type: TRACK_REQUEST,
    code,
    codeType,
    userInfo,
    rating,
    error,
    keycloak
})

export const ratingSuccess = (data) => ({
    type: RATING_SUCCESS,
    payload: { data }
})

export const ratingFailed = (error) => ({
    type: RATING_FAILED,
    error
})