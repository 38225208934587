/* eslint-disable react/display-name */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint no-param-reassign: ["error", { "props": false }] */
import React, { forwardRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'

import MaterialTable from '@material-table/core'
import { ExportCsv, ExportPdf } from '@material-table/exporters'
import { ThemeProvider } from '@material-ui/styles'

import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'

import theme from '../../util/theme'

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
}

const SimpleMaterialTable = props => {
  const {
    title,
    columns,
    rows,
    actions,
    options,
    onRowClick,
    onSelectionChange,
    detailPanel,
    togglePanelOnRowClick,
    editableColumns,
    exportable
  } = props

  const [tableColumns, setTableColumns] = useState([])
  const [tableRows, setTableRows] = useState([])

  useEffect(() => {
    // Convert array to string
    const newColumns = columns.map(col =>
      col.type === 'array' ? { ...col, type: 'string' } : col
    )
    newColumns.forEach(col => {
      if (!col.hidden) {
        col.hidden = false
      }
    })
    setTableColumns(newColumns)
  }, [columns, setTableColumns])

  useEffect(() => {
    const arrayColumns = columns
      .filter(col => col.type && col.type === 'array')
      ?.map(col => col.field)

    const dateColumns = columns
      .filter(
        col =>
          col.type &&
          (col.type === 'date' ||
            col.type === 'time' ||
            col.type === 'datetime')
      )
      ?.map(col => col.field)

    // Convert data
    const newRows = rows.map(row => {
      const newRow = row
      // Convert timestamps to date objects
      dateColumns.forEach(col => {
        newRow[col] = row[col] ? new Date(row[col]) : null
      })
      // Convert arrays to strings
      arrayColumns.forEach(col => {
        newRow[col] = row[col]?.join(', ')
      })
      return newRow
    })
    setTableRows(newRows)
  }, [rows, columns, setTableRows])

  return (
    // <S.StyledTableContainer>
    <ThemeProvider theme={theme}>
      <MaterialTable
        title={title}
        paging
        icons={tableIcons}
        columns={tableColumns}
        data={tableRows}
        actions={actions}
        options={_.merge(
          {
            rowStyle: { fontSize: '16px', fontFamily: 'Montserrat' },
            //padding: 'dense',
            pageSize: 10,
            pageSizeOptions: [5, 10, 25, 50],
            columnsButton: editableColumns,
            exportMenu: exportable
              ? [
                  {
                    label: 'Export PDF',
                    exportFunc: (cols, datas) => ExportPdf(cols, datas, title)
                  },
                  {
                    label: 'Export CSV',
                    exportFunc: (cols, datas) => ExportCsv(cols, datas, title)
                  }
                ]
              : []
          },
          options
        )}
        detailPanel={detailPanel}
        onRowClick={
          togglePanelOnRowClick
            ? (event, rowData, togglePanel) => togglePanel()
            : onRowClick
        }
        onSelectionChange={onSelectionChange}
        style={{
          boxShadow:
            '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)'
        }}
      />
    </ThemeProvider>
    // </S.StyledTableContainer>
  )
}

SimpleMaterialTable.defaultProps = {
  actions: [],
  editableColumns: false,
  exportable: false
}

SimpleMaterialTable.propTypes = {
  /** Table title */
  title: PropTypes.string,
  /** Table columns */
  columns: PropTypes.array,
  /** Table rows */
  rows: PropTypes.array,
  /** Table actions */
  actions: PropTypes.array,
  /** Table options */
  options: PropTypes.object,
  /** Row details panel */
  detailPanel: PropTypes.func,
  /** Row click function */
  onRowClick: PropTypes.func,
  /** Action on selection change */
  onSelectionChange: PropTypes.func,
  togglePanelOnRowClick: PropTypes.bool,
  editableColumns: PropTypes.bool,
  exportable: PropTypes.bool
}

export default SimpleMaterialTable
