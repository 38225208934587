import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as LogoImage } from '../../../../assets/images/logo/Sorgenia_CRI_1.svg';

const Logo = props => {
  const { open, isDesktop } = props

  return (
    <>
      {open && (
        <div style={isDesktop ? { width: 130 } : {}}>
          <LogoImage style={{ width: '100%' }} />
        </div>
      )}
      {!open && (
        <div
          style={
            isDesktop
              ? { marginLeft: '0px', width: 80 }
              : { marginTop: '0px', width: 80 }
          }
        >
          <LogoImage style={{ width: '80%' }} />
        </div>
      )}
    </>
  )
}


Logo.propTypes = {
  open: PropTypes.bool,
  isDesktop: PropTypes.bool
};

export default Logo;
