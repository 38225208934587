import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import TableChartIcon from '@material-ui/icons/TableChart';
import Group from '@material-ui/icons/Group';

const links = [
  {
    title: 'Search',
    href: '/output',
    icon: <SearchIcon />
  },
  {
    title: 'Track',
    href: '/track',
    icon: <TableChartIcon />
  },
  {
    title: 'Users',
    href: '/users',
    icon: <Group />
  }
];

export default links;
