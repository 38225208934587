import { createStore, compose, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { createRootReducers } from './reducers'
import { rootSaga } from './sagas'

const composeEnhancer = 
((process.env.NODE_ENV !== 'production' &&
window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)) ||
compose



const sagaMiddleware = createSagaMiddleware()



export const store = createStore(
    createRootReducers(),
    {},
    composeEnhancer(applyMiddleware(sagaMiddleware))
)

sagaMiddleware.run(rootSaga)