import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import { store } from './store'
import { env } from './env'
import { KeycloakProvider } from '@moxoff/axiom-auth';
import * as serviceWorker from './serviceWorker';

const authApp =
  env.authentication.enabled === 'true' ? 
    <KeycloakProvider env={env.authentication}>
      <App />
    </KeycloakProvider> :
    <App />


ReactDOM.render(
  <Provider store={store}>
    {authApp}
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();