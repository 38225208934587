export default {
  root: {
    '&$selected': {
      backgroundColor: '#38317512',
      '&:hover': {
        backgroundColor: '#38317512'
      }
    }
  },
  button: {
    '&:hover': {
      backgroundColor: '#38317512'
    }
  }
};
