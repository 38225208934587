import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useKeycloak } from '@moxoff/axiom-auth';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/styles';
import { Topbar, Sidebar, Footer, AccountIconCard } from '@moxoff/axiom-core';

import Logo from '../components/Logo/Logo';
//import { VersionImage, versionText } from '../components/Version/Version';
import * as S from './Main.styles';

import links from '../../../routes/links';
import {limitedHref, limitedGroups, limitedGroupsAdmin} from '../../../util/constants/constants'



/**
 * HOC that return a component wrapped into Sidebar Topbar and footer
 */
const Main = props => {
  const { children } = props;
  const [openSidebar, setOpenSidebar] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [userGroup, setUserGroup] = useState(null)
  // const {userRoles} = useSelector(state => state.ui)

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true
  });
  const { keycloak, authenticated } = useKeycloak();

  useEffect(() => {
    const loadUser = async () => {
      await keycloak()
        .loadUserInfo()
        .then(info => {
          setUserGroup(info.groups[0])
          setUserInfo({ name: info.name, email: info.email });
        });
    };
    if (userInfo === null && authenticated() === true) {
      loadUser();
    }
  }, [authenticated, keycloak, userInfo]);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  function filterAgencies() {
    const f = []
    links.forEach(link => {
      if (limitedGroups.includes(userGroup)) {
        if (limitedGroupsAdmin.includes(userGroup)) {
          f.push(link)
        }
        else {
          if (limitedHref.includes(link.href)) {
            f.push(link)
          }
        }
      } 
    });
    return f
  }

  return (
    <>
      <S.TopLine />
      <Topbar
        onClose={handleSidebarClose}
        onOpen={handleSidebarOpen}
        open={openSidebar}
        isDesktop={isDesktop}
        logo={<Logo open={openSidebar} isDesktop={isDesktop} />}
        withAuth
        authComponent={
          keycloak() &&
          authenticated() && (
            <AccountIconCard
              userName={userInfo?.name}
              userEmail={userInfo?.mail}
              //accountString="Manage your account"
              logoutString="Logout"
              userLogout={() => keycloak().logout()}
              //userAccountManagement={() => keycloak().accountManagement()}
            />
          )
        }
      />
      <Sidebar
        onClose={handleSidebarClose}
        open={openSidebar}
        variant={isDesktop ? 'permanent' : 'temporary'}
        //links={links.filter(l => limitedGroups.includes(userGroup) ? limitedGroupsAdmin.includes(userGroup) ? true : !limitedHrefAdmin.includes(l.href) : !limitedHref.includes(l.href))}
        links={filterAgencies()}
        //versionImage={<VersionImage />}
        //versionText={versionText}
      />
      {/* <Sidebar
        onClose={handleSidebarClose}
        open={openSidebar}
        variant={isDesktop ? 'permanent' : 'temporary'}
        //links={links.filter( l => userRoles.includes('global') ? true : !limitedHref.includes(l.href))}
        // versionImage={<VersionImage />}
        // versionText={versionText}
      /> */}
      <S.Main>
        <S.Content>{children}</S.Content>
        <Footer text="Created with ❤️ for your business. By mathematicians, engineers and designers who love to work together. This is Moxoff!" />
      </S.Main>
    </>
  );
};

Main.propTypes = {
  children: PropTypes.node
};

export default Main;
