import React, { useEffect, useMemo, useState } from 'react'

import MaterialReactTable from 'material-react-table';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Box, Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here

import { useKeycloak } from '@moxoff/axiom-auth';

import { env } from '../../env';


const Users = () => {
    const { sendAuthRequest, isLoading } = useKeycloak();

    const [data, setData] = useState([])

    // const getTrack = async () => {
    //     const resp = await sendAuthRequest( `${env.api.tracking.protocol_tracking}://${env.api.tracking.host_tracking}:${env.api.tracking.port_tracking}${env.api.tracking.route_tracking}/api/v1/get_track`,
    //     'GET',
    //     )
    //     setData(resp["data"])
    // }

    const getUsers = React.useCallback(async () => { 
        const resp = await sendAuthRequest( `${env.api.tracking.protocol_tracking}://${env.api.tracking.host_tracking}:${env.api.tracking.port_tracking}${env.api.tracking.route_tracking}/track/v1/get_users`,
        'GET',
        )
        const d = []
        resp["data"].map(c => {
            d.push({
                "username": c["username"], "codice_agente": c["codice_agente"], "firstName": c["firstName"], "lastName": c["lastName"],
                "email": c["email"], "telefono": c["telefono"], "group": c["group"], "group_code": c["group_code"],
                "crediti": `${c["crediti_spesi"]}/${c["crediti"]}`,"abilitato": c["abilitato"]
            })
            return c
        })
        setData(d)
      }, [sendAuthRequest]);
    
    useEffect(() => {
        if (data && data.length === 0) {
            getUsers()
        }
    }, [data, setData, getUsers]);

    const columns = useMemo(
        () => [
            {
                accessorKey: 'username',
                header: 'Username',
            },
            {
                accessorKey: 'codice_agente',
                header: 'Codice Agente',
            },
            {
                accessorKey: 'firstName',
                header: 'Nome Utente',
            },
            {
                accessorKey: 'lastName',
                header: 'Cognome Utente',
            },
            {
                accessorKey: 'email',
                header: 'Email',
            },
            {
                accessorKey: 'telefono',
                header: 'Telefono',
            },
            {
                accessorKey: 'group',
                header: 'Agenzia',
            },
            {
                accessorKey: 'group_code',
                header: 'Codice Agenzia',
            },
            {
                accessorKey: 'crediti',
                header: 'Crediti',
            },
            {
                accessorKey: "abilitato",
                header: "Abilitato"
            }
        ],
        [],
    );
    
    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: columns.map((c) => c.header),
    };
    
    const csvExporter = new ExportToCsv(csvOptions);

    const handleExportRows = (rows) => {
        csvExporter.generateCsv(rows.map((row) => row.original));
    };

    const handleExportData = () => {
        csvExporter.generateCsv(data);
    };
    
    return (
        <>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    align: "center",
                    justifyContent: "center",
                    paddingLeft: "5vw",
                    paddingTop: "5vw",
                }}
            >
                {isLoading && <CircularProgress />}
                {data && 
                    <MaterialReactTable
                        columns={columns}
                        data={data}
                        // enableColumnFilterModes
                        // enableColumnOrdering
                        enableGrouping
                        // enablePinning
                        renderTopToolbarCustomActions={({ table }) => (
                            <Box
                            sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                            >
                            <Button
                                color="primary"
                                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                                onClick={handleExportData}
                                startIcon={<FileDownloadIcon />}
                                variant="contained"
                            >
                                Export All Data
                            </Button>
                            <Button
                                disabled={table.getPrePaginationRowModel().rows.length === 0}
                                //export all rows, including from the next page, (still respects filtering and sorting)
                                onClick={() =>
                                handleExportRows(table.getPrePaginationRowModel().rows)
                                }
                                startIcon={<FileDownloadIcon />}
                                variant="contained"
                            >
                                Export All Rows
                            </Button>
                            <Button
                                disabled={table.getRowModel().rows.length === 0}
                                //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                                onClick={() => handleExportRows(table.getRowModel().rows)}
                                startIcon={<FileDownloadIcon />}
                                variant="contained"
                            >
                                Export Page Rows
                            </Button>
                            </Box>
                        )}
                    />
                }
            </div>
        </>
    )
}


export default Users
