import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import Typography from '@material-ui/core/Typography';

import * as S from './LoadingRoute.styles';

const LoadingRoute = () => {
  return (
    <div style={{ height: '80vh' }}>
      <S.LoadingContent>
        <CircularProgress />
        <br />
        <Typography variant="h6">Loading ...</Typography>
      </S.LoadingContent>
    </div>
  );
};

export default LoadingRoute;
