export default {
  rounded: {
    borderRadius: '20px',
    '&:first-child': {
      borderTopLeftRadius: '20px',
      borderTopRightRadius: '20px'
    },
    '&:last-child': {
      borderBottomLeftRadius: '20px',
      borderBottomRightRadius: '20px'
    }
  }
};
