import {
    RATING_FAILED, 
    RATING_REQUEST, 
    RATING_SUCCESS
} from './types'


const INIT_STATE = {
    title: "Sorgenia",
    loadingData: false,
    rating: [],
    error: ""
}

export const homeReducer = (state = INIT_STATE, action) => {
    switch(action.type){
        case RATING_REQUEST:
            return { ...state, rating: [], error: "", loadingData: true}
        case RATING_SUCCESS:
            return { ...state, rating: action.payload.data, error: "", loadingData: false}
        case RATING_FAILED:
            return { ...state, error: action.error, rating: [], loadingData: false}
        default:
            return { ...state }
    }
}

