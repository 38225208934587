import { colors } from '@material-ui/core';
import ThemeColors from '../models/moxoffColors.json';

const { primary, secondary, success, info, warning, error } = ThemeColors;
const white = '#FFFFFF';
const black = '#000000';

export default {
  black,
  white,
  gradient: {
    main:
      'linear-gradient(90deg,rgba(20, 40, 104, 1) 0%,rgba(20, 40, 104, 1) 100%)',
    light:
      'linear-gradient(90deg,rgba(20, 40, 104, 1) 0%,rgba(20, 40, 104, 1) 100%)'
  },
  primary: {
    contrastText: white,
    dark: primary[900],
    main: primary[500],
    light: primary[400]
  },
  secondary: {
    contrastText: white,
    dark: secondary[900],
    main: secondary[500],
    light: secondary[400]
  },
  success: {
    contrastText: white,
    dark: success[900],
    main: success[500],
    light: success[400]
  },
  info: {
    contrastText: white,
    dark: info[900],
    main: info[500],
    light: info[400]
  },
  warning: {
    contrastText: white,
    dark: warning[900],
    main: warning[500],
    light: warning[400]
  },
  error: {
    contrastText: white,
    dark: error[900],
    main: error[500],
    light: error[400]
  },
  text: {
    primary: '#142868',
    secondary: '#142868',
    link: '#142868'
  },
  background: {
    default: '#f8f8f8',
    transparent: '#f8f8f8d9',
    paper: white
  },
  linkNotActive: '#afafaf',
  icon: colors.blueGrey[500],
  divider: colors.grey[200]
};
