import { all, call, put, takeLatest } from "redux-saga/effects";
import { ratingFailed, ratingSuccess } from "./action";
import { RATING_REQUEST, TRACK_REQUEST } from "./types";
import { dataService } from '../../util/service/data.service'

function* dataRequestEffect(action) {
    try {
        // call API
        const response = yield call(dataService.getRating, action.code, action.codeType, action.userInfo, action.keycloak)
        // send data success
        const { data } = response
        yield put(ratingSuccess(data))
    } catch(e){
        // send data failed
        yield put(ratingFailed(e))
    }
}

function* sendTrackRequestEffect(action) {
    try {
        // call API
        const response = yield call(dataService.sendTrack, action.code, action.codeType, action.userInfo, action.rating, action.error, action.keycloak)
        // send data success
        const { data } = response
        console.log(data)
        //yield put(ratingSuccess(data))
    } catch(e){
        // send data failed
        // yield put(ratingFailed(e))
    }
}


export function* homeWatchers() {
    yield all([
        takeLatest(RATING_REQUEST, dataRequestEffect)
    ])
    yield all([
        takeLatest(TRACK_REQUEST, sendTrackRequestEffect)
    ])
}