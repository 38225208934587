import styled from 'styled-components';
import theme from '../../../util/theme';

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
  min-height: 100vh;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  max-width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  padding-top: 64px;
  padding-left: 27px;
  padding-right: 27px;
`;

export const TopLine = styled.div`
  height: 3px;
  width: 100%;
  z-index: 9999;
  position: fixed;
  background: ${theme.palette.gradient.light};
`;
