import { combineReducers } from 'redux'
import { homeReducer } from '../container/Home/reducer'
import { trackReducer } from '../container/Track/reducer'
import { usersReducer } from '../container/Users/reducer'


export const createRootReducers = () => combineReducers({
    home: homeReducer,
    track: trackReducer,
    users: usersReducer
})