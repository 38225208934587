import React, { useEffect, useMemo, useState } from 'react'

import MaterialReactTable from 'material-react-table';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Box, Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here

import { useKeycloak } from '@moxoff/axiom-auth';

import { env } from '../../env';


const Track = () => {
    const { sendAuthRequest, isLoading } = useKeycloak();

    const [data, setData] = useState([])

    // const getTrack = async () => {
    //     const resp = await sendAuthRequest( `${env.api.tracking.protocol_tracking}://${env.api.tracking.host_tracking}:${env.api.tracking.port_tracking}${env.api.tracking.route_tracking}/api/v1/get_track`,
    //     'GET',
    //     )
    //     setData(resp["data"])
    // }

    const getTrack = React.useCallback(async () => { 
        const resp = await sendAuthRequest( `${env.api.tracking.protocol_tracking}://${env.api.tracking.host_tracking}:${env.api.tracking.port_tracking}${env.api.tracking.route_tracking}/track/v1/get_track`,
        'GET',
        )
        resp["data"].map(c => {
            const cr = c["crediti"]
            c["crediti"] = `${c["crediti_spesi"]}/${cr}`
            delete c['crediti_spesi']
            return c
        })
        setData(resp["data"])
      }, [sendAuthRequest]);
    
    useEffect(() => {
        if (data && data.length === 0) {
            getTrack()
        }
    }, [data, setData, getTrack]);

    const columns = useMemo(
        () => [
            {
                accessorKey: 'username',
                header: 'Username',
            },
            {
                accessorKey: 'codice_agente',
                header: 'Codice Agente',
            },
            {
                accessorKey: 'nome_utente',
                header: 'Nome Utente',
            },
            {
                accessorKey: 'cognome_utente',
                header: 'Cognome Utente',
            },
            {
                accessorKey: 'agenzia',
                header: 'Agenzia',
            },
            {
                accessorKey: 'codice_agenzia',
                header: 'Codice Agenzia',
            },
            {
                accessorKey: 'date',
                header: 'Date',
            },
            {
                accessorKey: 'time',
                header: 'Time',
            },
            // {
            //     accessorFn: (row) => `${row.crediti_spesi} - ${row.crediti}`,
            //     id: 'crediti',
            //     header: 'Crediti',
            // },
            {
                accessorKey: 'crediti',
                header: 'Crediti',
            },
            {
                accessorKey: 'input_utente',
                header: 'Input Utente',
            },
            {
                accessorKey: 'success',
                header: 'Success',
            },
            {
                accessorKey: 'area1_power',
                header: 'Area1 Power',
            },
            {
                accessorKey: 'area2_power',
                header: 'Area2 Power',
            },
            {
                accessorKey: 'area3_power',
                header: 'Area3 Power',
            },
            {
                accessorKey: 'area4_power',
                header: 'Area4 Power',
            },
            {
                accessorKey: 'area1_gas',
                header: 'Area1 Gas',
            },
            {
                accessorKey: 'area2_gas',
                header: 'Area2 Gas',
            },
            {
                accessorKey: 'area3_gas',
                header: 'Area3 Gas',
            },
            {
                accessorKey: 'area4_gas',
                header: 'Area4 Gas',
            },
            {
                accessorKey: 'blacklist',
                header: 'Blacklist',
            },
            {
                accessorKey: 'viplist',
                header: 'Viplist',
            },
            {
                accessorKey: 'prospect',
                header: 'Prospect',
            },
            {
                accessorKey: 'no_result',
                header: 'No Result',
            },
            {
                accessorKey: 'piva_risultato',
                header: 'PIVA Risultato',
            },
            {
                accessorKey: 'cf_risultato',
                header: 'CF Risultato',
            },
            {
                accessorKey: 'ragione_sociale',
                header: 'Ragione Sociale',
            },
        ],
        [],
    );
    
    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: columns.map((c) => c.header),
    };
    
    const csvExporter = new ExportToCsv(csvOptions);

    const handleExportRows = (rows) => {
        csvExporter.generateCsv(rows.map((row) => row.original));
    };

    const handleExportData = () => {
        csvExporter.generateCsv(data);
    };
    
    return (
        <>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    align: "center",
                    justifyContent: "center",
                    paddingLeft: "5vw",
                    paddingTop: "5vw",
                }}
            >
                {isLoading && <CircularProgress />}
                {data && 
                    <MaterialReactTable
                        columns={columns}
                        data={data}
                        // enableColumnFilterModes
                        // enableColumnOrdering
                        enableGrouping
                        // enablePinning
                        renderTopToolbarCustomActions={({ table }) => (
                            <Box
                            sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                            >
                            <Button
                                color="primary"
                                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                                onClick={handleExportData}
                                startIcon={<FileDownloadIcon />}
                                variant="contained"
                            >
                                Export All Data
                            </Button>
                            <Button
                                disabled={table.getPrePaginationRowModel().rows.length === 0}
                                //export all rows, including from the next page, (still respects filtering and sorting)
                                onClick={() =>
                                handleExportRows(table.getPrePaginationRowModel().rows)
                                }
                                startIcon={<FileDownloadIcon />}
                                variant="contained"
                            >
                                Export All Rows
                            </Button>
                            <Button
                                disabled={table.getRowModel().rows.length === 0}
                                //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                                onClick={() => handleExportRows(table.getRowModel().rows)}
                                startIcon={<FileDownloadIcon />}
                                variant="contained"
                            >
                                Export Page Rows
                            </Button>
                            </Box>
                        )}
                    />
                }
            </div>
        </>
    )
}


export default Track
